export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
        REGION: "us-east-2",
        BUCKET: "notes-app-api-prod-serverlessdeploymentbucket-1c0rn51nksh8h"
    },
    apiGateway: {
        REGION: "us-east-2",
        URL: " https://o83jnyiz0f.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_2Q29jZPFV",
        APP_CLIENT_ID: "3qvd4i4rp9q4pjsn03io1dgfmd",
        IDENTITY_POOL_ID: "us-east-2:e3060738-d52f-4d15-b730-f167ca7f603a"
    }
};
